import React from 'react'
// import { Link } from 'gatsby'
import Icon from '../../icons'
import Video from '../../video/video'

import * as styles from './hero.module.css'
import { Image } from '../../Image'

const Hero = ({ type, record }) => {
  // const [videoPlay, setVideoPlay] = useState(false)
  // const [videoDisplay, setVideoDisplay] = useState('background')
  // const [audioPlay, setAudioPlay] = useState(false)

  // useEffect(() => {
  //   const handleScroll = event => {
  //     if (window.scrollY > 0 && videoDisplay === 'background' && videoPlay) {
  //       setVideoDisplay('float')
  //     } else if ((window.scrollY === 0 && videoDisplay === 'float') || (!videoPlay && videoDisplay === 'float')) {
  //       setVideoDisplay('background')
  //     }
  //   }
  //
  //   window.addEventListener('scroll', handleScroll)
  //
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll)
  //   }
  // }, [videoPlay, videoDisplay])

  // const clickEvent = () => {
  //   if (record._type === 'video') {
  //     setVideoPlay(!videoPlay)
  //   }

  // if (record._type === 'audio') {
  //   if (typeof window !== 'undefined') {
  //     const heroAudio = document.getElementById('heroAudio')
  //     if (heroAudio.paused) {
  //       heroAudio.play()
  //       setAudioPlay(true)
  //     } else {
  //       heroAudio.pause()
  //       setAudioPlay(false)
  //     }
  //   }
  // }
  // }

  // const backgroundImage = () => {
  //   if (record.backgroundImage && record.backgroundImage.asset) {
  //     return `${record.backgroundImage.asset.url}?q=10&format=auto&w=1280&h=960`
  //   } else if (record.featureImage && record.featureImage.asset) {
  //     return `${record.featureImage.asset.url}?q=10&format=auto&w=1280&h=960`
  //   } else return null
  // }
  return (
    <section className={`${styles.hero} ${styles[`type${type}`]} ${styles[`record${record._type}`]}`} role='presentation'>
      <div className={styles.content}>
        <div className={styles.titles}>
          {(type === 'video' && record._type === 'video') && (
            <div className={styles.videoDisplay}>
              <div className={styles.videoView}>
                <Video videoId={record.videoId} type={record.youtubePlayer ? 'youtube' : 'standard'} mute={1} />
              </div>
            </div>
          )}
          {(record._type === 'audio' || type === 'audio') && (
            <div className={styles.audioHeader}>
              <div className={styles.audioHeaderPik}>
                <Image imgObj={record.featureImage} props={{ width: 572, height: 572, alt: record.title }} className={styles.audioCover} />
              </div>
              <div className={styles.audioHeadline}>
                <div className={styles.audioHeadlineSeries}>{record.title}</div>
                <h1 className={styles.audioHeadlineTitle}>{record.seriesTitle}</h1>
                {/* <div className={styles.audioHeadlineTags}> */}
                {/*   <div className={styles.tags}> */}
                {/*     {record.tags.map((tag, tagIndex) => ( */}
                {/*       <Link key={tagIndex} to={`/content-hub/category/${tag.slug?.current}/`} className={styles.tag}> */}
                {/*         {tag.title} */}
                {/*       </Link> */}
                {/*     ))} */}
                {/*   </div> */}
                {/* </div> */}
                {(record.spotifyUrl || record.appleUrl || record.youTubeUrl) && (
                  <div className={`${styles.listenOn} ${styles.icons}`}><span>Listen on</span>
                    {record.spotifyUrl && (<a className={styles.spotify} href={record.spotifyUrl} target='_blank' rel='noreferrer'><Icon symbol='spotify' /></a>)}
                    {record.appleUrl && (<a className={styles.apple} href={record.appleUrl} target='_blank' rel='noreferrer'><Icon symbol='apple' /></a>)}
                    {record.youTubeUrl && (<a className={styles.youtube} href={record.youTubeUrl} target='_blank' rel='noreferrer'><Icon symbol='youtube' /></a>)}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default Hero
